import { socioGrpcClient } from "@/setup/socioGrpcClient";
import i18n from "@/setup/i18n";
import { AosVuexOrmModel, modelUtils } from "@socotec.io/socio-aos-component";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import {
  Disposition,
  DispositionNode,
  DocumentReview,
  Observation,
  TypologyTypeNode,
  CtNodeObservation,
  ReferencialCtNode,
  ObservationStatement,
  QuotationValue,
  RiskAssessment,
  StatementType,
} from "@/models";
import { cloneDeep, orderBy, uniqBy, uniqWith } from "lodash";
import store from "@/store";

export const observationOriginEnum = {
  OBSERVATION: "OBSERVATION",
  DISPOSITION: "DISPOSITION",
  DOCUMENT_REVIEW: "DOCUMENT_REVIEW",
  RAPSOTEC_CT: "RAPSOTEC_CT",
};
import { computed } from "vue";
import { useRxdbCollection } from "@/composables/useRxdbCollection";

const nodesGetterFactory = ({
  observationUuid,
  Model,
  contentType,
  objectIdAttr = null,
}) => {
  const instance = Model.query().where("observation", observationUuid).first();
  if (!instance) return;
  return {
    objectId: objectIdAttr
      ? instance[objectIdAttr]
      : instance.referencialNodeUuid,
    contentType,
  };
};

export const subObservationMap = {
  [observationOriginEnum.OBSERVATION]: {
    NodeModel: (observationUuid) => {
      if (store.getters["project/getIsPicassoVisit"]) return DispositionNode;
      const observation = Observation.find(observationUuid);
      const { assetType } = observation.relatedAosItems[0];
      if (assetType === "component") return AosVuexOrmModel["AosComponentType"];
      return null;
    },

    nodeInfoGetter: (observationUuid) => {
      if (store.getters["project/getIsPicassoVisit"]) {
        return nodesGetterFactory({
          observationUuid,
          Model: Disposition,
          contentType: "dispositiontype",
        });
      }
      return null;
    },
  },
  [observationOriginEnum.DISPOSITION]: {
    Model: () => Disposition,
    NodeModel: () => DispositionNode,
    referencialTypeName: (i18n) => i18n.t("disposition"),

    nodeInfoGetter: (observationUuid) => {
      return nodesGetterFactory({
        observationUuid,
        Model: Disposition,
        contentType: "dispositiontype",
      });
    },
    itemName: "disposition",
    observationReverseFk: "dispositionTypesList",
    referencialFk: "dispositionType",
    nodeState: "missionDispositionNodes",
    rootPathGetters: "disposition/getCurrentRootPath",
    actions: {
      destroy: "disposition/destroyDisposition",
      export: "disposition/exportDispositions",
    },
  },

  [observationOriginEnum.DOCUMENT_REVIEW]: {
    Model: () => DocumentReview,
    NodeModel: () => TypologyTypeNode,
    referencialTypeName: (i18n) => i18n.t("documentReview"),

    nodeInfoGetter: (observationUuid) => {
      return nodesGetterFactory({
        observationUuid,
        Model: DocumentReview,
        contentType: "typologytype",
      });
    },
    itemName: "documentReview",
    observationReverseFk: "typologyTypesList",
    referencialFk: "typologyType",
    nodeState: "currentTypologyTypeNodes",
    rootPathGetters: "documentReview/getCurrentRootPath",
    actions: {
      destroy: "documentReview/destroyDocumentReview",
      export: "documentReview/exportDocumentReviews",
    },
  },

  [observationOriginEnum.RAPSOTEC_CT]: {
    Model: () => CtNodeObservation,
    NodeModel: () => ReferencialCtNode,
    referencialTypeName: (i18n) => i18n.t("technicalInspection"),

    nodeInfoGetter: (observationUuid) => {
      return nodesGetterFactory({
        observationUuid,
        Model: CtNodeObservation,
        contentType: "ctnode",
      });
    },
    itemName: "ctNodeObservation",
    observationReverseFk: "ctNodesList",
    referencialFk: "uuid", // hack, no actual amos_back ct_node so far
    nodeState: "missionCtNodes",
    rootPathGetters: "ctNodeObservation/getCurrentRootPath",
    actions: {
      destroy: "ctNodeObservation/destroyCtNodeObservation",
      export: "",
    },
  },
};
export const buildStatementRequestForConnect = (statement) => {
  return {
    uuid: statement.uuid,
    observation: statement.observation,
    justification: statement.justification,
    documents: statement.documents,
    objectTypeStatement: statement.objectTypeStatement,
    createdBy: statement.createdBy,
    modifiedBy: statement.modifiedBy,
  };
};
export const buildStatementRequest = (statement) => {
  return socioGrpcClient.javascriptToRequest(
    socioGrpcClient.amos_back.observation.ObservationStatementRequest,
    {
      uuid: statement.uuid,
      observation: statement.observation,
      justification: statement.justification,
      documents: statement.documents,
      objectTypeStatement: statement.objectTypeStatement,
    }
  );
};

export const buildBIMDataPinRequest = (pin) => {
  return socioGrpcClient.javascriptToRequest(
    socioGrpcClient.amos_back.observation.BIMDataPinRequest,
    {
      uuid: pin?.uuid,
      observation: pin?.observation,
      pageNumber: pin.pageNumber,
      modelId: pin.modelId,
      submission: pin.submission,
      guid: pin.guid,
      x: pin.x,
      y: pin.y,
      documentOriginId: pin.documentOriginId,
    }
  );
};

export const buildMetadataRequest = (metadata) => {
  return socioGrpcClient.javascriptToRequest(
    socioGrpcClient.amos_back.observation.ObservationMetadataRequest,
    metadata
  );
};

export const riskIcons = {
  LOWEST: "mdi-circle-slice-1",
  LOW: "mdi-circle-slice-2",
  MODERATE: "mdi-circle-slice-4",
  HIGH: "mdi-circle-slice-7",
  UNDEFINED: "mdi-cicle-outline",
  // TODO: replace by mdi-circle-slice-1 when the icon will be available
  DANGER_SECURITY: "mdi-circle-slice-1",
  DANGER_STRUCTURE: "mdi-circle-slice-1",
  EVOLUTION_AVANCEE: "mdi-circle-slice-1",
  DEBUT_EVOLUTION: "mdi-circle-slice-1",
  SANS_GRAVITE: "mdi-circle-slice-1",
};

export const riskColors = {
  Elevé: "#D80F0C",
  Modéré: "#FF6533",
  Faible: "#FCB902",
  "Très faible": "#00833F",
  "Danger pour la sécurité": "#FF0000",
  "Danger pour la structure": "#F49304",
  "Evolution avancée": "#FFFF00",
  "Début d'évolution": "#AECB09",
};

export const riskAssessment = (observationUuid) => {
  const formatRiskLevels = computed(() => {
    const observation = Observation.find(observationUuid);
    const riskData = RiskAssessment.find(observation?.riskAssessment);
    return {
      label: riskData ? i18n.t(`risks.${riskData.code}`) : "",
      icon: riskData ? getIcons(riskData.code) : "",
    };
  });

  const changeColor = computed(
    () => riskColors[formatRiskLevels.value.label] || "05843A"
  );

  const getIcons = (riskLevel) => riskIcons[riskLevel];

  return { getIcons, formatRiskLevels, changeColor };
};

export const PICASSO_STATUS_ENUM = {
  OLD: "OLD",
  OLD_UPDATED: "OLD_UPDATED",
  NEW: "NEW",
  INFORMATION: "INFORMATION",
};

export const getPicassoObsStatusColor = (status) => {
  const mapping = {
    [PICASSO_STATUS_ENUM.OLD]: "#333333",
    [PICASSO_STATUS_ENUM.OLD_UPDATED]: "#0082DE",
    [PICASSO_STATUS_ENUM.NEW]: "#A9C409",
    [PICASSO_STATUS_ENUM.INFORMATION]: "#B818AD",
  };
  return mapping[status];
};

export const durationTypes = () => {
  const fieldArray = (data) => [
    data.number,
    i18n.tc(`durationTypes.${data.durationType}`, data.number) + " -",
    i18n.t("durationTypes.starting"),
    dayjs(data.start).format("DD/MM/YYYY"),
  ];

  const getPeriodicityString = (data) => {
    return fieldArray(data).reduce((curr, rest) =>
      modelUtils.smartConcat(curr, rest, " ")
    );
  };

  return { getPeriodicityString };
};

export const getSortedDistinctStatements = ({ statements }) => {
  const distinct = uniqWith(statements, (itemA, itemB) => {
    return (
      itemA.objectTypeStatement === itemB.objectTypeStatement &&
      itemA.observation === itemB.observation
    );
  });
  return orderBy(
    distinct,
    [(item) => QuotationValue.find(item.quotationValue)?.value],
    ["asc"]
  );
};

export const getMinQuotedStatement = ({
  observationUuids,
  filterClause = () => true,
}) => {
  const uniqueStatements = uniqBy(
    ObservationStatement.query()
      .where((o) => observationUuids.includes(o.observation) && filterClause(o))
      .get(),
    (item) => item.statementType
  );

  const sortedStatements = orderBy(
    uniqueStatements,
    [(item) => QuotationValue.find(item.quotationValue)?.value],
    ["desc"]
  );

  if (!sortedStatements.length) {
    return undefined;
  }

  const smallestStatement = sortedStatements.at(-1);
  return QuotationValue.find(smallestStatement.quotationValue)?.value;
};

export const updateObservationStatementsInRxDB = async (
  statements,
  observationUuid,
  collection
) => {
  const statementsCopy = cloneDeep(statements);
  const selector = { observation: observationUuid };
  const rxDocStatements = await collection((c) =>
    c
      .find({ selector })
      .exec()
      .then((docs) => docs.map((doc) => doc.toMutableJSON()))
  );

  const formattedStatements = statementsCopy.map((s) => ({
    uuid: s.uuid,
    updatedAt: s.updatedAt,
    createdAt: s.createdAt,
    justification: s.justification,
    statementType: s.statementType,
    quotationValue: s.quotationValue,
    createdByFirstname: s.createdByFirstname,
    modifiedByFirstname: s.modifiedByFirstname,
    createdByLastname: s.createdByLastname,
    modifiedByLastname: s.modifiedByLastname,
    observation: s.observation,
    objectTypeStatement: s.objectTypeStatement,
    documents: s.documents,
    createdBy: s.createdBy,
    modifiedBy: s.modifiedBy,
  }));

  const upserted = await collection((c) => c.bulkUpsert(formattedStatements));
  const { error = [] } = upserted;
  if (error.length) {
    console.warn("Error encountered while upserting statements", error);
  }

  await ObservationStatement.insertOrUpdate({ data: formattedStatements });

  // Delete statements
  const statementsToDelete = rxDocStatements.filter(
    (cos) =>
      !statementsCopy
        .map((s) => s.objectTypeStatement)
        .includes(cos.objectTypeStatement)
  );

  await collection((c) => c.bulkRemove(statementsToDelete.map((s) => s.uuid)));
  await ObservationStatement.delete((entry) =>
    statementsToDelete.map((sToDelete) => sToDelete.uuid).includes(entry.uuid)
  );
};

const getObjectTypeStatementsFromObjectIds = async (
  objectTypeStatementCollection,
  objectIds
) => {
  if (!objectIds.length) return [];
  return await objectTypeStatementCollection((collection) => {
    return collection.find().where("objectId").in(objectIds).exec();
  });
};

export const createOrUpdateStatementsFromDispositions = async ({
  objectTypeStatementCollection,
  dispositionTypeIds,
  observationUuid,
  user,
  isPicassoCT,
}) => {
  // Picasso Workflow
  const objectTypeStatements = await getObjectTypeStatementsFromObjectIds(
    objectTypeStatementCollection,
    dispositionTypeIds
  );
  if (isPicassoCT) {
    return objectTypeStatements.map((item) => item.uuid);
  }
  const observationStatements = [];

  objectTypeStatements.forEach((item) => {
    observationStatements.push({
      ...item.toJSON(),
      uuid: uuidv4(),
      objectTypeStatement: item.uuid,
      statementType: item.statementType,
      statementTypeData: StatementType.find(item.statementType),
      quotationValueData: QuotationValue.find(item.quotationValue),
      quotationValue: item.quotationValue,
      justification: "",
      documents: [],
      actionTypes: [],
      observation: observationUuid,
      createdBy: user.usermanagementUuid,
      createdByFirstname: user.firstName,
      createdByLastname: user.lastName,
      modifiedBy: user.usermanagementUuid,
      modifiedByFirstname: user.firstName,
      modifiedByLastname: user.lastName,
    });
  });

  return observationStatements;
};

export const setObservationStatementToPush = async (uuid) => {
  const { execOnCollection: ObservationStatementCollection } =
    useRxdbCollection("observation_statement");

  const rxDocStatements = await ObservationStatementCollection((c) =>
    c
      .find({
        selector: {
          observation: uuid,
        },
      })
      .exec()
  );

  const statementsData = rxDocStatements.map((item) => item.toMutableJSON());

  const quotationValueUuids = statementsData.map(
    ({ quotationValue }) => quotationValue
  );
  const QuotationValueFromStatement = QuotationValue.query()
    .whereIdIn(quotationValueUuids)
    .get();

  const lowestQuotationValue = QuotationValueFromStatement.reduce(
    (min, current) => {
      return current.value < min.value ? current : min;
    },
    QuotationValueFromStatement[0]
  );
  const statementWithLowestQuotationValue = statementsData.find(
    (statement) => statement.quotationValue === lowestQuotationValue.uuid
  );

  const statementReq = statementsData.map(buildStatementRequestForConnect);

  return {
    statementsData,
    statementReq,
    lowestQuotationValue,
    statementWithLowestQuotationValue,
  };
};

export const makeSubObservationModelFromReferencialId = ({
  modelClass,
  referencialIdKey,
  referencialIds,
  observationUuid,
}) => {
  if (!referencialIds.length) return [];
  const instances = modelClass
    .query()
    .where("observation", observationUuid)
    .get();

  if (!instances.length) {
    return referencialIds.map((referencialId) => ({
      uuid: uuidv4(),
      referencialTypeId: referencialId,
    }));
  }

  const instancesMap = {};
  instances.forEach((instance) => {
    instancesMap[instance[referencialIdKey]] = instance;
  });

  return referencialIds.map((referencialId) => {
    const instance = instancesMap[referencialId];
    if (instance) {
      return { uuid: instance.uuid, referencialTypeId: referencialId };
    }
    return { uuid: uuidv4(), referencialTypeId: referencialId };
  });
};
