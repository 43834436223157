import { utils, AosVuexOrmModel } from "@socotec.io/socio-aos-component";
import store from "@/store";
import i18n from "@/setup/i18n";

export const picassoDocumentTypes = [
  "GAMME",
  "MAIL",
  "PHOTO",
  "PLAN",
  "SCHEMA",
];
export const blueCastleDocumentTypes = ["MAIL", "PHOTO"];

export const retrieveGedIdsFromAosSelection = async (aosSelection) => {
  // Retrieve ged ids from sites
  const gedIds = new Set();
  const unknownSitePaths = new Set();
  utils.aosConst.AOS_ITEMS_MODULE_NAMES.forEach((moduleName) => {
    if (!aosSelection[moduleName]) {
      return;
    }
    // Retrieve aos path from items
    const itemPathes = utils.aosConst.AOS_ITEMS[moduleName]
      .aosModelClass()
      .query()
      .whereIdIn(aosSelection[moduleName])
      .get()
      .map((item) => item.path);
    // Retrieve site from items path and add it to gedIds set
    itemPathes.forEach((itemPath) => {
      const [sitePath] = itemPath.split(".");
      const site = AosVuexOrmModel["AosSite"]
        .query()
        .where("path", sitePath)
        .first();
      if (!site) {
        unknownSitePaths.add(sitePath);
      } else {
        gedIds.add(site.originId);
      }
    });
  });
  if (unknownSitePaths.size > 0) {
    const sitesResponse = await store.dispatch("aos/site/fetchSites", {
      metadata: {
        filters: JSON.stringify({
          path__in: Array.from(unknownSitePaths).join(", "),
        }),
      },
    });

    const { data: sites } = sitesResponse;
    return new Set([...gedIds, ...sites.map((site) => site.originId)]);
  }
  return gedIds;
};

export const getExtensionAndNameFromFile = async (fileName) => {
  const fileNameArray = fileName.split(".");
  if (fileNameArray.length === 1) {
    setTimeout(async () => {
      await store.dispatch(
        "notifications/showWarningNotification",
        i18n.t("documentFormModal.form.invalidFile")
      );
    }, 300);
    return fileNameArray;
  }

  const extension = fileNameArray.pop();
  const name = fileNameArray.join("");

  if (!name || !extension) {
    await store.dispatch(
      "notifications/showWarningNotification",
      i18n.t("documentFormModal.form.invalidFile")
    );
    return [name, extension];
  }

  return [name, extension];
};
